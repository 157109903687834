<template>
  <main-layout>
    <div class="w-min-content">
      <base-button @click="$router.back()" :back="true">
        <horizontal-arrow />
      </base-button>
    </div>
    <div class="expertSinglePage">
      <div class="expertSinglePage__container">
        <div class="d-flex flex-column align-items-center">
          <div class="expertSinglePage__avatarContainer">
            <avatar
              user-role="experts"
              :user-code="expert.code"
              :image-name="expert.image"
              size="500"
              width="150px"
              height="150px"
            />
            <short-book-information
              book-on="expert"
              :information="expert"
              v-if="loginUser.role_id === 4"
            />
            <div class="expertSinglePage__priceContainer">
              <div v-for="(service, i) in services" :key="service.id">
                <div
                  v-if="i < serviceShow"
                  class="expertSinglePage__priceObject"
                  :class="{
                    'expertSinglePage__priceObject-border':
                      i !== serviceShow - 1,
                  }"
                >
                  <div class="expertSinglePage__priceTitle">
                    {{ service.name }}
                  </div>
                  <div
                    v-for="consultation in service.consultations"
                    :key="consultation.id"
                    class="expertSinglePage__priceContent"
                  >
                    {{ `${consultation.name}: €${consultation.price}, ` }}
                    <duration-time :data="consultation.duration" />
                  </div>
                </div>
              </div>
              <div
                v-if="services.length > 2"
                class="expertSinglePage__priceBtn"
                @click="showPrice()"
              >
                {{ showFullService ? $t("HideAll") : $t("ShowAll") }}
                <svg
                  :class="{ 'rotate-180': showFullService }"
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M16.502 7.5L10.0012 14.0008L3.50037 7.5"
                    stroke="#0F243F"
                    stroke-linecap="round"
                  />
                </svg>
              </div>
            </div>
          </div>
        </div>
        <div class="expertSinglePage__contentContainer">
          <h1 class="expertSinglePage__title">
            <short-name :name="expert.first_name + ' ' + expert.last_name" />
          </h1>
          <div class="d-flex">
            <div class="expertSinglePage__expertLanguages-wrapper">
              <div
                v-for="language in expert.languages"
                :key="language.id"
                class="expertSinglePage__expertLang"
              >
                {{ language.code }}
              </div>
            </div>
            <div class="expertSinglePage__expertService-wrapper">
              <div
                v-for="service in expert.services"
                :key="service.id"
                class="expertSinglePage__expertService"
              >
                {{ service.name }}
              </div>
            </div>
          </div>
          <div class="expertSinglePage__biography">
            <div id="text">
              <div id="textContent" v-html="serviceDescriptions"></div>
            </div>
            <div
              v-if="showReadMoreBtn"
              class="expertSinglePage__read serviceSinglePage__read"
              @click="showFullDescription = !showFullDescription"
            >
              {{ showFullDescription ? $t("hideBtn") : $t("readMoreBtn") }}
              <div
                class="transition"
                :class="{ 'serviceSinglePage__read-icon': showFullDescription }"
              >
                <horizontal-arrow />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="haveServices" class="expertSinglePage__provide">
      <h2>{{ $t("providedServices") }}</h2>
      <div class="row">
        <div
          class="col-12 col-lg-6"
          v-for="service in expert.services"
          :key="service.id"
        >
          <provide-services :provide-services="service" />
        </div>
      </div>
    </div>
  </main-layout>
</template>

<script>
import MainLayout from "@/layouts/MainLayout";
import BaseButton from "@/components/UI/buttons/BaseButton";
import HorizontalArrow from "@/components/UI/icons/arrows/HorizontalArrow";
import Avatar from "@/components/UI/images/Avatar";
import ShortBookInformation from "@/components/elements/card/ShortBookInformation";
import ShortName from "@/components/components-helpers/ShortName";
import ProvideServices from "@/components/elements/card/ProvideServices";
import { mapGetters } from "vuex";
import DurationTime from "@/components/components-helpers/DurationTime";

export default {
  name: "ExpertSinglePage",
  components: {
    DurationTime,
    ProvideServices,
    ShortName,
    MainLayout,
    BaseButton,
    HorizontalArrow,
    Avatar,
    ShortBookInformation,
  },
  data() {
    return {
      expert: {
        image: "",
        first_name: "",
        last_name: "",
        biography: "",
        short_biography: "",
        languages: [],
        services: [],
      },
      services: [],
      serviceShow: 2,
      showFullService: false,
      showFullDescription: false,
    };
  },
  async mounted() {
    this.expert = await this.getExpertInformation();
    this.services = await this.getExpertConsultations();
  },
  methods: {
    showPrice() {
      if (this.services.length > this.serviceShow) {
        this.serviceShow = this.services.length;
        this.showFullService = true;
      } else {
        this.serviceShow = 2;
        this.showFullService = false;
      }
    },
    async getExpertInformation() {
      return this.$http
        .get("/experts/code/" + this.$route.params.expert)
        .then(({ data }) => {
          return data.body;
        });
    },
    async getExpertConsultations() {
      return this.$http
        .get(`/services/expert/${this.expert.id}/consultations`)
        .then(({ data }) => {
          return data.body;
        });
    },
  },
  computed: {
    ...mapGetters({
      loginUser: "CURRENT_USER_INFORMATION",
    }),
    haveServices() {
      return !!this.expert.services ? this.expert.services.length > 0 : false;
    },
    showReadMoreBtn() {
      return !!this.expert.biography
        ? this.expert.biography.length > 500
        : false;
    },
    serviceDescriptions() {
      if (!!this.expert.biography) {
        if (this.showFullDescription) {
          return this.expert.biography;
        } else {
          return this.expert.biography.length > 500
            ? this.expert.biography.substring(0, 500) + "..."
            : this.expert.biography.substring(0, 300);
        }
      } else {
        return "";
      }
    },
  },
};
</script>

<style></style>
